import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";

/**
 * Direttiva che crea l'header visualizzato una volta effettuato il login. Esso conterrà il pulsante 
 * di logout, il titolo della pagina e il logout
 */
angular.module('app').directive("header", ($window, LibraryApplicationData, NotificationService, $sessionStorage, $location, $mdDialog, $translate, $state, SessionStorageService, $mdSidenav) => {
	return {
		link: link,
		restrict: 'AE',
		templateUrl: 'app/shared/header/header.html'
	};
	// Quando la larghezza della finestra è maggiore di 600px pulsante del menu si nasconde e, se è rimasto aperto, rimane l'icona Close. Quindi dopo i 600px reimposto l'icona di default
	function link(scope: any, element: any, attrs: any) {
		// Verifica se sono nella parte amministrativa della LibraryApplicationData
		scope.adminMode = $sessionStorage.adminMode;

		scope.notificationList = [];

		// Porta alla home
		scope.logoClicked = () => {
			// Verifico quanti elementi ho nel Session Storage
			let count = SessionStorageService.count();

			// ci sarà sempre un altro elemento nel session storage, dunque decremento il risultato
			count = count ? count : 0;

			if (scope.adminMode) {
				$state.go('app.libraryApp.homeAdmin', { searchId: count });
			} else {
				$state.go('app.libraryApp.home', { searchId: count });
			}
		}

		// Argomento per cui filtrare di default nella terza tab "Guida"
		scope.defaultArgumentId = LibraryApplicationData.constants.DEFAULT_ARGUMENT_ID;

		// Filtri per la ricerca di default in Library
		scope.filters = {
			searchedText: '', // ricerca testuale nella tab 'Library'
			myActivities: false, // switch che abilita il filtraggio per le mie attività nella tab 'Library'
			forMe: false,
			suggested: { id: 1, name: $translate.instant("home.tabs.filters.select.ALL") }, // switch che abilita il filtraggio per i suggeriti nella tab 'Library'
			selectedTechSkillTags: [], // chips selezionate riguardanti le competenze tech nella tab 'Library'
			selectedSoftSkillTags: [], // chips selezionate riguardanti le competenze soft nella tab 'Library'
			selectedTopicTags: [], // chips selezionate riguardanti gli argomenti nella tab 'Library'
			selectedTypeTags: [], // chips selezionate rigaurdanti il tipo di oggetto nella tab 'Library'
			competence: { id: 1, name: $translate.instant("home.tabs.filters.select.ALL") }, // competenza scelta (tech/soft) nella tab 'Library'
			status: { id: 1, name: $translate.instant("home.tabs.filters.select.ALL") }, // status scelto (visti, non visti, tutti) nella tab 'Library'
			selectedPeople: [], // lista di persone scelte fra i suggeritori nella tab 'Suggeriti'
			sorting: { id: 1, label: "S", name: $translate.instant("home.sorting.SCORE") }, // Ordinamento scelto per i risultati della ricerca, di default è l'ordinamento per pertinenza
			openedTab: 3, // tab aperta
			forceSearchSuggestedItems: false, //forzo la ricerca degli elementi suggeriti
			forceForMeSearch: false,
			subTabForMeSelected: 0,
			subTabSelected: 0
		};

		// Torna alla pagina di home, nella sezione how to
		scope.backToHowTo = () => {
			scope.filters.selectedTopicTags.length = 0;
			scope.filters.selectedTopicTags.push(scope.defaultArgumentId);
			// Salvo nel Session Storage il nuovo set di filtri (che in questo caso è solo la terza tab aperta)
			SessionStorageService.saveObject(1, scope.filters);
			$state.go('app.libraryApp.home', { searchId: 1 }, { reload: true });
		}

		/*
		angular.element($window).bind('resize', () => {
			// Collegamento all'oggetto principale
			scope.libraryApplicationData = LibraryApplicationData;

			scope.width = $window.innerWidth;

			if (scope.width > 600) {
				scope.hamburgerMenu = 'menu';
			}
			scope.$digest();
		}) */

		scope.isLeftSidenavOpen = () => {
			if ($mdSidenav('leftMenuSidenav').isOpen()) {
				return true;
			}

			return false;
		}

		// Controller per la modale che avverte l'utente che la funzionalità non è ancora attiva
		scope.functionalityNotActiveController = ($scope: any, $mdDialog: any) => {
			// Chiude la modale
			$scope.confirm = () => {
				$mdDialog.hide();
			};
		}

		// Apre una modale che avverte l'utente che la funzionalità non è ancora attiva
		scope.functionalityNotActive = (event: any, selectedRent: any) => {
			$mdDialog.show({
				controller: scope.functionalityNotActiveController,
				templateUrl: 'functionalityNotActive.html',
				parent: angular.element(document.body),
				targetEvent: event,
				clickOutsideToClose: true
			})
				.then(() => {
				}, () => {
					// In caso di dismiss non faccio niente
				});
		}

		if (!scope.adminMode && scope.globalApplicationData.jwtPayload) {
			scope.isUserHeaderVisible = true;
		}

		// Verifico se l'utente è abilitato ad accedere alla GTP
		if (scope.hasNotApplicationEnabled('TRAININGPLATFORM')) {
			scope.isNotGTPEnabled = true;
		} else {
			scope.isNotGTPEnabled = false;
		}

		scope.goToGtp = (page?: string) => {
			if (!scope.isNotGTPEnabled) {
				let protocol = "";
				let secondLevelDomain = "";
				let thirdLevelDomain = "";
				// Calcolo il dominio di appartenenza per chiamare i servizi Rest
				let port = window.location.protocol === "http:" ? 80 : 443;
				protocol = window.location.protocol === "http:" ? "http" : "https";
				let firstDot = 0;
				let lastDot = window.location.hostname.lastIndexOf(".");
				if (lastDot != -1) {
					firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
				}
				if (firstDot == -1) {
					firstDot = 0;
				}
				secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
				if (secondLevelDomain.charAt(0) == ".") {
					secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
				}
				// Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
				if (window.location.hostname.indexOf("localhost") == 0) {
					thirdLevelDomain = "localhost.";
				}
				else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0 && firstDot <= 5) {
					thirdLevelDomain = "atf.";
				}
				else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
					thirdLevelDomain = "test.";
				}
				else {
					thirdLevelDomain = "www.";
				}

				let linkGtp: string = protocol + "://" + thirdLevelDomain + secondLevelDomain;
				$window.location.href = linkGtp + page;
			}
		}

		scope.showNotifications = (mdOpenMenu: any, event: any) => {
			// loads notifications
			NotificationService.getNotifications.query({
				fromRecord: 0,
				numRecords: 1000,
				getOnlyUnread: true
			}).$promise
				.then((res: SenecaResponse<any>) => {
					if (res.response) {
						scope.notificationList = res.response.notifications;
					}
				})
				.catch((error: Error) => {
					scope.notificationList = [];
				});

			mdOpenMenu(event)
		}

		scope.goToDevelopmentPath = () => {
			let protocol = "";
			let secondLevelDomain = "";
			let thirdLevelDomain = "";
			// Calcolo il dominio di appartenenza per chiamare i servizi Rest
			let port = window.location.protocol === "http:" ? 80 : 443;
			protocol = window.location.protocol === "http:" ? "http" : "https";
			let firstDot = 0;
			let lastDot = window.location.hostname.lastIndexOf(".");
			if (lastDot != -1) {
				firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
			}
			if (firstDot == -1) {
				firstDot = 0;
			}
			secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
			if (secondLevelDomain.charAt(0) == ".") {
				secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
			}
			// Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
			if (window.location.hostname.indexOf("localhost") == 0) {
				thirdLevelDomain = "localhostd.";
			}
			else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0 && firstDot <= 5) {
				thirdLevelDomain = "atfd.";
			}
			else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
				thirdLevelDomain = "testdevelopmentpath.";
			}
			else {
				thirdLevelDomain = "developmentpath.";
			}

			let linkDP: string = protocol + "://" + thirdLevelDomain + secondLevelDomain;
			$window.location.href = linkDP;
		}
	}
});